import axios from 'axios';
import { ActionTree, ActionContext } from 'vuex';
import { RootState } from '../../state';
import { MutationTypes } from './mutations';
import userState from './state';
import activityList from './const';

// eslint-disable-next-line no-shadow
export enum ActionTypes {
  loginUser = 'loginUser',
  fetchUser = 'fetchUser',
  refreshToken = 'refreshToken',
  forcedLogout = 'forcedLogout',
  fetchStudentById = 'fetchStudentById',
  fetchProgramById = 'fetchProgramId',
  fetchTeamDoc = 'fetchTeamDoc',
  fetchTeamList = 'fetchTeamList',
  updateProgramState = 'updateProgramState',
  updateProgramData = 'updateProgramData',
  getProgram = 'getProgram',
  makeOffer = 'makeOffer',
  createStudent = 'createStudent',
  getDataWithShareableUrl = 'getDataWithShareableUrl',
  addNewAdk = 'adkNewAdk',
  getUserPermissions = 'getUserPermissions',
  deleteAdkOrCategory = 'deleteAdkOrCategory',
  updateCompleted = 'updateCompleted',
  editCategory = 'editCategory'
}
type UserActionCtx = ActionContext<typeof userState, RootState>;

export interface UserActions extends ActionTree<typeof userState, RootState> {
  loginUser: (ctx: UserActionCtx, payload) => Promise<void>;
}

export const actions: UserActions = {
  updateProgramState({ commit }, data) {
    commit(MutationTypes.GET_PROGRAM, data);
  },

  clearProgramDoc({ commit }) {
    commit(MutationTypes.CLEAR_PROGRAM);
  },

  async loginUser({ commit }, data) {
    try {
      commit(MutationTypes.LOGIN_USER, data);
      return data;
    } catch (err) {
      commit(MutationTypes.LOGIN_ERROR, err);
      throw err;
    }
  },

  async refreshToken({ commit, dispatch }, data) {
    try {
      const tknData = {
        refresh_token: localStorage.getItem('apollo-refresh-token')
      };
      const resp = await axios.post(`${process.env.VUE_APP_AUTH0}/generate-access-token`, tknData, {
        headers: {
          'Content-Type': 'application/json'
        }
      });
      if (resp.data.data) {
        localStorage.setItem('apollo-token', resp.data.data.access_token);
        localStorage.setItem('apollo-refresh-token', resp.data.data.refresh_token);
        window.location.href = `/portfolio`;
      }
    } catch (error) {
      console.log(error);
    }
  },
  // eslint-disable-next-line consistent-return
  async getAuthUrl({ commit, dispatch }, data) {
    try {
      let google;
      if (data?.type === 'google') {
        google = true;
      } else if (data?.type === 'outlook') {
        google = false;
      }
      const response = await axios.get(
        `${process.env.VUE_APP_CALENDAR_SERVICE}/auth-url?redirect_uri=${window.location.origin}/meetings&google=${google}&public_id=${data?.public_id}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('apollo-token')}`
          }
        }
      );
      return response.data.data;
    } catch (error) {
      console.log(error);
    }
  },
  // eslint-disable-next-line consistent-return
  async refreshCalendarTokenData({ commit, dispatch }, payload) {
    try {
      let google;
      if (payload.type === 'google') {
        google = true;
      } else if (payload.type === 'outlook') {
        google = false;
      }
      const response = await axios.get(
        `${process.env.VUE_APP_CALENDAR_SERVICE}/refresh-access-token?refresh_token=${payload.refreshToken}&google=${google}&public_id=${payload?.document_id}&document_id=${payload?.document_id}&document_type=${payload?.document_type}&adk_type=${payload?.adk_type}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('apollo-token')}`
          }
        }
      );
      return response.data;
    } catch (error) {
      console.log(error);
    }
  },
  // eslint-disable-next-line consistent-return
  async createCalendar({ commit, dispatch }, data) {
    try {
      const response: any = await axios.post(
        `${process.env.VUE_APP_CALENDAR_SERVICE}/calendar?token=${data.accessToken}&google=${data.google}&adkName=${data.adkName}`,
        data.eventData,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('apollo-token')}`
          }
        }
      );
      return response.data;
    } catch (error) {
      if (error.response.data.error.code === 'UNAUTHORIZED') {
        const refreshTokenData: any = await axios.get(
          `${process.env.VUE_APP_CALENDAR_SERVICE}/refresh-access-token?refresh_token=${data.refreshToken}&google=${data.google}`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('apollo-token')}`
            }
          }
        );
        const responseData: any = await await axios.post(
          `${process.env.VUE_APP_CALENDAR_SERVICE}/calendar?token=${refreshTokenData.data.data.access_token}&google=${data.google}`,
          data.eventData,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('apollo-token')}`
            }
          }
        );
        return responseData.data;
      }
      console.log(error);
    }
  },
  // eslint-disable-next-line consistent-return
  async updateCalendar({ commit, dispatch }, data) {
    try {
      const response: any = await axios.patch(
        `${process.env.VUE_APP_CALENDAR_SERVICE}/calendar?token=${data.accessToken}&event_id=${data.eventId}&google=${data.google}&adkName=${data.adkName}`,
        data.eventData,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('apollo-token')}`,
            'Content-Type': 'application/json'
          }
        }
      );
      return response.data;
    } catch (error) {
      if (error.response.data.error.code === 'UNAUTHORIZED') {
        const refreshTokenData: any = await axios.get(
          `${process.env.VUE_APP_CALENDAR_SERVICE}/refresh-access-token?refresh_token=${data.refreshToken}&google=${data.google}`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('apollo-token')}`
            }
          }
        );
        const responseData: any = await axios.patch(
          `${process.env.VUE_APP_CALENDAR_SERVICE}/calendar?token=${refreshTokenData.data.data.access_token}&event_id=${data.eventId}&google=${data.google}`,
          data.eventData,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('apollo-token')}`,
              'Content-Type': 'application/json'
            }
          }
        );
        return responseData.data;
      }
      console.log(error, 'error');
    }
  },
  // eslint-disable-next-line consistent-return
  async getCalendarTokenData({ commit, dispatch }, tokenData) {
    try {
      let google;
      if (tokenData.type === 'google') {
        google = true;
      } else if (tokenData.type === 'outlook') {
        google = false;
      }
      const response = await axios.get(
        `${process.env.VUE_APP_CALENDAR_SERVICE}/auth-token?code=${tokenData.code}&redirect_uri=${window.location.origin}/meetings&google=${google}&public_id=${tokenData?.public_id}&document_id=${tokenData?.document_id}&document_type=${tokenData?.document_type}&adk_index=${tokenData?.adk_index}&adk_type=${tokenData?.adk_type}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('apollo-token')}`
          }
        }
      );
      return response.data;
    } catch (error) {
      console.log(error);
    }
  },
  // eslint-disable-next-line consistent-return
  async getCalendarEvents({ commit, dispatch }, data) {
    try {
      let query;
      if (data.calendarData.calendarType === 'month') {
        query = `token=${data.accessToken}&year=${data.calendarData.year}&month=${data.calendarData.month}&google=${data.google}`;
      } else if (data.calendarData.calendarType === 'day') {
        query = `token=${data.accessToken}&year=${data.calendarData.year}&month=${data.calendarData.month}&day=${data.calendarData.day}&google=${data.google}`;
      } else if (
        data.calendarData.calendarType === '4day' ||
        data.calendarData.calendarType === 'week'
      ) {
        const weekDays = {
          start_time: data.calendarData.startDate,
          end_time: data.calendarData.endDate
        };
        query = `token=${data.accessToken}&week=${encodeURIComponent(
          JSON.stringify(weekDays)
        )}&google=${data.google}`;
      } else {
        const weekDays = {
          start_time: data.calendarData.startDate,
          end_time: data.calendarData.endDate
        };
        query = `token=${data.accessToken}&week=${encodeURIComponent(
          JSON.stringify(weekDays)
        )}&google=${data.google}`;
      }
      console.log('data', data);
      if (data?.public_id) {
        query = `${query}&public_id=${data?.public_id}`;
      }
      const response = await axios.get(
        `${process.env.VUE_APP_CALENDAR_SERVICE}/calendar?${query}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('apollo-token')}`
          }
        }
      );
      return response.data;
    } catch (error) {
      console.log(error);
    }
  },

  // eslint-disable-next-line consistent-return
  async getInternshipBadge({ commit, dispatch }, data) {
    try {
      let queryParams = `program_id=${data?.program_id}`;
      if (data?.public_id) {
        queryParams = `${queryParams}&public_id=${data?.public_id}`;
      }
      const response = await axios.get(
        `${process.env.VUE_APP_CALENDAR_SERVICE}/internship-badge?${queryParams}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('apollo-token')}`
          }
        }
      );
      return response.data.data;
    } catch (error) {
      console.log(error);
    }
  },

  // eslint-disable-next-line consistent-return
  async deleteCalendarEvent({ commit, dispatch }, data) {
    try {
      const response = await axios.delete(
        `${process.env.VUE_APP_CALENDAR_SERVICE}/calendar?token=${data.accessToken}&event_id=${data.eventId}&google=${data.google}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('apollo-token')}`
          }
        }
      );
      return response.data;
    } catch (error) {
      console.log(error);
    }
  },
  async fetchUser({ commit, dispatch }, data) {
    try {
      const resp = await axios.get(`${process.env.VUE_APP_AUTH0}/get-mongo-user`, {
        headers: {
          Authorization: `Bearer ${data}`
        }
      });
      commit(MutationTypes.LOGIN_USER, resp.data);

      return resp.data;
    } catch (err) {
      console.log(err);
      if (err) {
        dispatch('refreshToken', localStorage.getItem('apollo-token'));
        dispatch('forcedLogout');
      }
      commit(MutationTypes.LOGIN_ERROR, err);
      throw err;
    }
  },
  async refreshDiscordAccessToken({ commit, dispatch }, data) {
    try {
      const resp = await axios.get(
        `${process.env.VUE_APP_COWORK_SERVICE}/refresh-token?user_type=${data.userType}&program_id=${data.programId}&public_id=${data.public_id}&refresh_token=${data.refreshToken}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('apollo-token')}`
          }
        }
      );
      return resp.data;
    } catch (err) {
      console.log(err.response);
      return err.response.data;
    }
  },
  // eslint-disable-next-line consistent-return
  async createDiscordChannels({ commit, dispatch }, data) {
    try {
      const resp = await axios.post(`${process.env.VUE_APP_COWORK_SERVICE}/channels`, data, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('apollo-token')}`
        }
      });
      return resp;
    } catch (err) {
      console.log(err.response);
    }
  },
  async sendMessageInDiscord({ commit, dispatch }, data) {
    try {
      const resp = await axios.post(`${process.env.VUE_APP_COWORK_SERVICE}/send-message`, data, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('apollo-token')}`
        }
      });
      return resp;
    } catch (err) {
      console.log(err.response);
      return err.response.data;
    }
  },
  // eslint-disable-next-line consistent-return
  async addMembersToDiscordRole({ commit, dispatch }, data) {
    try {
      const resp = await axios.post(`${process.env.VUE_APP_COWORK_SERVICE}/add-to-role`, data, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('apollo-token')}`
        }
      });
      return resp;
    } catch (error) {
      console.log(error);
    }
  },
  // eslint-disable-next-line consistent-return
  async fetchProgramById({ commit, dispatch }, data) {
    try {
      const document_type = 'Program';
      const resp = await axios.get(
        `${process.env.VUE_APP_PROGRAM_DOC}/document?document_type=${document_type}&document_id=${data.program_id}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('apollo-token')}`
          }
        }
      );
      commit(MutationTypes.GET_PROGRAM, resp.data.data);
      return resp.data.data;
    } catch (err) {
      console.log(err);
    }
  },
  // eslint-disable-next-line consistent-return
  async getDocumentByIdAndType({ commit, dispatch }, data) {
    try {
      let queryParams = `document_type=${data.document_type}&document_id=${data.document_id}`;
      if (data?.public_id) {
        queryParams = `${queryParams}&public_id=${data?.public_id}`;
      }
      const resp = await axios.get(`${process.env.VUE_APP_PROGRAM_DOC}/document?${queryParams}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('apollo-token')}`
        }
      });
      return resp.data.data;
    } catch (err) {
      console.log(err);
    }
  },
  // eslint-disable-next-line consistent-return
  async fetchUserType({ commit, dispatch }, data) {
    try {
      let queryparams = `program_id=${data.program_id}`;
      if (data?.public_id) {
        queryparams = `${queryparams}&public_id=${data?.public_id}`;
      }
      const resp = await axios.get(`${process.env.VUE_APP_USER_SERVICE}/user-role?${queryparams}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('apollo-token')}`
        }
      });
      commit(MutationTypes.SET_ROLE, resp.data.data.user_type);
    } catch (err) {
      console.log(err);
    }
  },

  async fetchTeamDoc({ commit, dispatch }, data) {
    try {
      const document_type = 'ProgramTeam';
      const resp = await axios.get(
        `${process.env.VUE_APP_PROGRAM_DOC}/document-team?document_type=${document_type}&user_id=${data.user_id}&public_id=${data.public_id}&program_id=${data.program_id}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('apollo-token')}`
          }
        }
      );
      return resp.data.data;
    } catch (err) {
      return err.response;
    }
  },

  async fetchTeamList({ commit, dispatch }, data) {
    try {
      const resp = await axios.get(
        `${process.env.VUE_APP_PROGRAM_DOC}/programs/teams?program_id=${data.program_id}&public_id=${data.public_id}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('apollo-token')}`
          }
        }
      );
      return resp.data.data;
    } catch (err) {
      console.log(err);
      return err;
    }
  },

  // eslint-disable-next-line consistent-return
  async fetchStudentById({ commit, dispatch }, data) {
    try {
      let queryparams = `document_type=Student&user_id=${data?.user_id}&program_id=${data?.program_id}`;
      if (data?.public_id) {
        queryparams = `${queryparams}&public_id=${data?.public_id}`;
      }
      const resp = await axios.get(
        `${process.env.VUE_APP_PROGRAM_DOC}/document-team?${queryparams}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('apollo-token')}`
          }
        }
      );
      commit(MutationTypes.GET_PROGRAM, resp.data.data);
      return resp.data.data;
    } catch (err) {
      return err.response;
    }
  },

  // eslint-disable-next-line consistent-return
  async updateAdkData({ commit, dispatch }, data) {
    try {
      const resp = await axios.patch(
        `${process.env.VUE_APP_PROGRAM_DOC}/program-adk?document_type=${data.document_type}&document_id=${data.program_id}`,
        data.data,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('apollo-token')}`
          }
        }
      );
      commit(MutationTypes.GET_PROGRAM, resp.data.data);
      return resp.data;
    } catch (err) {
      console.log(err);
      return err.response;
    }
  },
  async addNewAdk({ commit }, data) {
    try {
      const resp = await axios.post(`${process.env.VUE_APP_PROGRAM_DOC}/add-program-adk`, {
        programOrTemplateId: data.programOrTemplateId,
        adk_name: data.adk_name,
        data: data.data,
        document_type: data.document_type,
        isCatMatch: data.isCatMatch,
        index: data.index
      });
      return resp.data;
    } catch (err) {
      return err.response;
    }
  },
  async deleteAdkOrCategory({ commit }, payload) {
    try {
      const resp = await axios.post(`${process.env.VUE_APP_PROGRAM_DOC}/delete-adk`, payload);
      return resp.data;
    } catch (err) {
      return err.response;
    }
  },
  async addCategory({ commit }, payload) {
    try {
      const resp = await axios.post(`${process.env.VUE_APP_PROGRAM_DOC}/add-category`, payload, {
        headers: {
          'Content-Type': 'application/json'
        }
      });
      commit(MutationTypes.GET_PROGRAM, resp.data.data);
      return resp.data;
    } catch (err) {
      return err.response;
    }
  },
  async editCategory({ commit }, data) {
    try {
      const resp = await axios.post(
        `${process.env.VUE_APP_PROGRAM_DOC}/edit-category`,
        {
          program_id: data.programId,
          category_name: data.categoryName,
          document_type: data.documentType,
          old_category: data.oldCategory,
          icon: data.icon
        },
        {
          headers: {
            'Content-Type': 'application/json'
          }
        }
      );
      return resp.data;
    } catch (err) {
      return err.response;
    }
  },
  async updateProgramData({ commit, dispatch }, data) {
    try {
      const resp = await axios.patch(
        `${process.env.VUE_APP_PROGRAM_DOC}/program?program_id=${data.program_id}`,
        data.data,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('apollo-token')}`
          }
        }
      );
      return resp.data;
    } catch (err) {
      console.log(err);
      return err;
    }
  },
  async publishProgram({ commit, dispatch }, data) {
    try {
      const resp = await axios.post(`${process.env.VUE_APP_PROGRAM_DOC}/program/publish`, data, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('apollo-token')}`
        }
      });
      commit(MutationTypes.GET_PROGRAM, resp.data.data);
      return resp.data;
    } catch (err) {
      console.log(err);
      return err;
    }
  },
  async updateAdksPosition({ commit, dispatch }, data) {
    try {
      const resp = await axios.patch(
        `${process.env.VUE_APP_PROGRAM_DOC}/program/manage-adks?document_id=${data.document_id}&document_type=${data.document_type}`,
        { adks: data.adks, categories: data.categories },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('apollo-token')}`
          }
        }
      );
      commit(MutationTypes.GET_PROGRAM, resp.data.data);
      return resp.data;
    } catch (err) {
      console.log(err);
      return err;
    }
  },
  async deleteVideoAskResponse({ commit, dispatch }, data) {
    try {
      const resp = await axios.delete(
        `${process.env.VUE_APP_VIDEOASK_SERVICE}/videoask-response?user_type=${data.user_type}&program_id=${data.program_id}&public_id=${data.public_id}&adk_name=${data.adk_name}&key_name=${data.key_name}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('apollo-token')}`
          }
        }
      );
      commit(MutationTypes.GET_PROGRAM, resp.data.data);
      return resp.data;
    } catch (err) {
      console.log(err);
      return err;
    }
  },
  getProgram({ commit, dispatch }, data) {
    const document_type = 'Program';
    return new Promise((resolve, reject) => {
      axios
        .get(
          `${process.env.VUE_APP_PROGRAM_DOC}/document?document_type=${document_type}&document_id=${data.program_id}`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('apollo-token')}`
            }
          }
        )
        .then(response => {
          resolve(response.data.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  async getAdkIndexByName({ commit, dispatch }, adkName) {
    let index = 0;
    if (activityList.includes(adkName.toLowerCase())) {
      index = activityList.indexOf(adkName.toLowerCase());
    }
    return index;
  },
  // eslint-disable-next-line consistent-return
  async getPrivacyPolicy() {
    try {
      const response = await axios.get(`https://www.iubenda.com/api/privacy-policy/21126817`);
      return response.data;
    } catch (error) {
      console.log(error);
    }
  },

  // eslint-disable-next-line consistent-return
  async getTermsAndConditions() {
    try {
      const response = await axios.get(`https://www.iubenda.com/api/terms-and-conditions/21126817`);
      return response.data;
    } catch (error) {
      console.log(error);
    }
  },

  async forcedLogout() {
    localStorage.removeItem('apollo-token');
    localStorage.removeItem('apollo-refresh-token');
    window.location.href = `/login?forcedLogout=true`;
  },

  async revertVersion({ commit, dispatch }, data) {
    try {
      const resp = await axios.post(
        `${process.env.VUE_APP_REVISION_SERVICE}/revision/revert`,
        data,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('apollo-token')}`
          }
        }
      );
      commit(MutationTypes.GET_PROGRAM, resp.data.data);
      return resp.data;
    } catch (err) {
      console.log(err);
      return err;
    }
  },
  async getUserPortfolios({ commit, dispatch }, data) {
    try {
      const resp = await axios.get(
        `${process.env.VUE_APP_USER_SERVICE}/user-portfolios?user_id=${data.user_id}`
      );
      return resp.data.data;
    } catch (err) {
      console.log(err);
      return err;
    }
  },
  async fetchUserById({ commit, dispatch }, data) {
    try {
      let queryparams = `document_type=User&document_id=${data?.user_id}`;
      if (data?.public_id) {
        queryparams = `${queryparams}&public_id=${data?.public_id}`;
      }
      const resp = await axios.get(`${process.env.VUE_APP_PROGRAM_DOC}/document?${queryparams}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('apollo-token')}`
        }
      });
      return resp.data;
    } catch (err) {
      console.log(err);
      return err;
    }
  },
  async publishProgramRevision({ commit, dispatch }, data) {
    try {
      const resp = await axios.post(`${process.env.VUE_APP_REVISION_SERVICE}/revision`, data, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('apollo-token')}`
        }
      });
      commit(MutationTypes.GET_PROGRAM, resp.data.data);
      return resp.data;
    } catch (err) {
      console.log(err);
      return err;
    }
  },
  async getProgramRevision({ commit, dispatch }, data) {
    try {
      const resp = await axios.get(
        `${process.env.VUE_APP_REVISION_SERVICE}/revision?program_id=${data.program_id}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('apollo-token')}`
          }
        }
      );
      commit(MutationTypes.GET_PROGRAM, resp.data.data);
      return resp.data;
    } catch (err) {
      console.log(err);
      return err;
    }
  },
  // eslint-disable-next-line consistent-return
  async getUserByKeyAndValue({ commit, dispatch }, data) {
    try {
      const resp = await axios.get(
        `${process.env.VUE_APP_USER_SERVICE}/user/key?key=${data.key}&value=${data.value}`,
        {
          headers: {
            'Content-Type': 'application/json'
          }
        }
      );
      return resp.data;
    } catch (err) {
      console.log(err);
    }
  },
  async updatePortfolio({ commit, dispatch }, data) {
    try {
      const resp = await axios.patch(
        `${process.env.VUE_APP_USER_SERVICE}/user-portfolio?type=${data.type}`,
        data.payload,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('apollo-token')}`
          }
        }
      );
      return resp.data.data;
    } catch (err) {
      console.log(err);
      return err;
    }
  },
  async uploadProfileImage({ commit, dispatch }, data) {
    try {
      const resp = await axios.post(
        `${process.env.VUE_APP_S3_PROFILE_IMAGE_SERVICE}/profileImageResize`,
        data
      );
      return resp.data.data;
    } catch (err) {
      console.log(err);
      return err;
    }
  },
  async uploadImage({ commit, dispatch }, data) {
    try {
      const resp = await axios.post(`${process.env.VUE_APP_S3_PROFILE_IMAGE_SERVICE}/image`, data);
      return resp.data;
    } catch (err) {
      console.log(err);
      return err;
    }
  },
  async removeProfileImage({ commit, dispatch }, data) {
    try {
      const resp = await axios.delete(`${process.env.VUE_APP_USER_SERVICE}/user/profile`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('apollo-token')}`
        }
      });
      return resp.data.data;
    } catch (err) {
      console.log(err);
      return err;
    }
  },
  async getTokens({ commit, dispatch }, data) {
    try {
      let API_ENDPOINT = `${process.env.VUE_APP_REDIS}/get-tokens`;
      if (data?.public_id) {
        API_ENDPOINT = `${API_ENDPOINT}?public_id=${data?.public_id}`;
      }
      const resp = await axios.get(`${API_ENDPOINT}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('apollo-token')}`
        }
      });
      return resp.data;
    } catch (err) {
      console.log(err);
      return err;
    }
  },
  // eslint-disable-next-line consistent-return
  async getSponsorLinkByShareCode({ commit, dispatch }, payload) {
    try {
      const resp = await axios.get(
        `${process.env.VUE_APP_REDIS_SERVICE}/v1/sponsor-link/share-code?share_code=${payload?.share_code}`,
        {
          headers: {
            'Content-Type': 'application/json'
          }
        }
      );
      return resp.data.data;
    } catch (err) {
      console.log(err);
    }
  },
  // eslint-disable-next-line consistent-return
  async getWalletByOwnerId({ commit, dispatch }, payload) {
    try {
      const resp = await axios.get(
        `${process.env.VUE_APP_REDIS_SERVICE}/wallet?owner_id=${payload?.owner_id}`,
        {
          headers: {
            'Content-Type': 'application/json'
          }
        }
      );
      return resp.data.data;
    } catch (err) {
      console.log(err);
    }
  },
  async getProductInfos({ commit, dispatch }) {
    try {
      const resp = await axios.get(`${process.env.VUE_APP_PAYMENT_SERVICE}/product-information`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('apollo-token')}`
        }
      });
      return resp.data.data;
    } catch (err) {
      console.log(err);
      return err;
    }
  },
  async getAllStudents({ commit, dispatch }) {
    try {
      const resp = await axios.get(`${process.env.VUE_APP_STUDENT_SERVICE}/students`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('apollo-token')}`
        }
      });
      return resp.data.data;
    } catch (err) {
      console.log(err);
      return err;
    }
  },
  async disconnectUserFromCalendar({ commit, dispatch }, payload) {
    try {
      const resp = await axios.post(
        `${process.env.VUE_APP_CALENDAR_SERVICE}/calendar/disconnect`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('apollo-token')}`
          }
        }
      );
      return resp.data.data;
    } catch (err) {
      console.log(err);
      return err;
    }
  },
  async updateInvitedUserFlag({ commit, dispatch }, data) {
    try {
      const resp = await axios.patch(`${process.env.VUE_APP_USER_SERVICE}/user/invitedUser`, data);
      return resp.data;
    } catch (err) {
      console.log(err);
      return err;
    }
  },
  // eslint-disable-next-line consistent-return
  async getStackholders({ commit, dispatch }, data) {
    try {
      const resp = await axios.get(
        `${process.env.VUE_APP_USER_SERVICE}/stackholders?user_type=${data.user_type}&program_id=${data.program_id}&document_type=${data.document_type}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('apollo-token')}`
          }
        }
      );
      return resp.data.data;
    } catch (err) {
      console.log(err);
    }
  },
  async addStackholders({ commit, dispatch }, data) {
    try {
      const resp = await axios.post(`${process.env.VUE_APP_USER_SERVICE}/add/organizer`, data, {
        headers: {
          'Content-Type': 'application/json'
        }
      });
      return resp;
    } catch (error) {
      console.log(error.response);
      return error.response.data;
    }
  },
  async removeStackholder({ commit, dispatch }, data) {
    try {
      const resp = await axios.delete(
        `${process.env.VUE_APP_USER_SERVICE}/remove/organizer?user_id=${data.user_id}&program_id=${data.program_id}&document_type=${data.document_type}`,
        {
          headers: {
            'Content-Type': 'application/json'
          }
        }
      );
      return resp;
    } catch (error) {
      console.log(error.response);
      return error.response.data;
    }
  },
  // eslint-disable-next-line consistent-return
  async getLatestDocumentByUserId({ commit, dispatch }, data) {
    try {
      const resp = await axios.get(
        `${process.env.VUE_APP_PROGRAM_DOC}/latest/adk-index?document_type=${data.document_type}&user_id=${data.user_id}`,
        {
          headers: {
            'Content-Type': 'application/json'
          }
        }
      );
      return resp.data.data;
    } catch (err) {
      console.log(err);
      // return err;
    }
  },

  // eslint-disable-next-line consistent-return
  async makeOffer({ commit, dispatch }, data) {
    try {
      const resp = await axios.post(
        `${process.env.VUE_APP_STUDENT_SERVICE}/resolve-student-application`,
        data,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('apollo-token')}`
          }
        }
      );
      return resp.data.data;
    } catch (err) {
      console.log(err.response);
    }
  },
  async getSponsorLinks({ commit, dispatch }) {
    try {
      const resp = await axios.get(`${process.env.VUE_APP_REDIS}/get-sponsor-links`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('apollo-token')}`
        }
      });
      return resp.data;
    } catch (err) {
      console.log(err);
      return err;
    }
  },
  async getTokensBySponsorIdAnddata({ commit, dispatch }, data) {
    try {
      const resp = await axios.get(
        `${process.env.VUE_APP_SPONSOR_SERVICE_LINKS}/tokens?owner_id=${data.owner_id}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('apollo-token')}`
          }
        }
      );
      return resp.data;
    } catch (err) {
      console.log(err);
      return err;
    }
  },

  async transferTokens({ commit, dispatch }, data) {
    try {
      const resp = await axios.post(`${process.env.VUE_APP_REDIS}/transfer-tokens`, data, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('apollo-token')}`
        }
      });
      return resp.data;
    } catch (err) {
      return err.response.data;
    }
  },

  async saveDeliverableSetting({ commit, dispatch }, data) {
    try {
      const ENDPOINT_API = process.env.VUE_APP_DELIVERABLE_SETTING;
      const resp = await axios.patch(
        `${ENDPOINT_API}?program_id=${data.program_id}`,
        data.deliverableObj,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('apollo-token')}`
          }
        }
      );
      commit(MutationTypes.GET_PROGRAM, resp.data.data);
      return resp.data.data;
    } catch (err) {
      console.log(err);
      return err;
    }
  },

  async getTransactionHistory({ commit, dispatch }) {
    try {
      const resp = await axios.get(`${process.env.VUE_APP_REDIS}/get-transactions`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('apollo-token')}`
        }
      });
      return resp.data;
    } catch (err) {
      console.log(err);
      return err;
    }
  },

  async createSponsorLinks({ commit, dispatch }, data) {
    try {
      const resp = await axios.post(`${process.env.VUE_APP_REDIS}/sponsorlink`, data, {
        headers: {
          'Content-Type': 'application/json'
        }
      });
      return resp.data;
    } catch (err) {
      console.log(err);
      return err;
    }
  },
  async revokeSponsorLink({ commit, dispatch }, data) {
    try {
      const resp = await axios.post(`${process.env.VUE_APP_REDIS}/revokeLink`, data, {
        headers: {
          'Content-Type': 'application/json'
        }
      });
      return resp.data;
    } catch (err) {
      console.log(err);
      return err;
    }
  },
  async createCheckoutSession({ commit, dispatch }, data) {
    try {
      const resp = await axios.post(`${process.env.VUE_APP_STRIPE_CHECKOUT}`, data, {
        headers: {
          'Content-Type': 'application/json'
        }
      });
      return resp.data;
    } catch (err) {
      console.log(err);
      return err;
    }
  },
  async createInvoice({ commit, dispatch }, data) {
    try {
      const resp = await axios.post(`${process.env.VUE_APP_STRIPE_INVOICE}`, data, {
        headers: {
          'Content-Type': 'application/json'
        }
      });
      return resp.data;
    } catch (err) {
      console.log(err);
      return err;
    }
  },
  async updateForceChangedPwdFlag({ commit, dispatch }, data) {
    try {
      const resp = await axios.patch(
        `${process.env.VUE_APP_USER_SERVICE}/user/force_changes_pwd_flag?user_id=${data.user_id}`,
        { force_changed_pwd: data.force_changed_pwd },
        {
          headers: {
            'Content-Type': 'application/json'
          }
        }
      );
      return resp.data.data;
    } catch (err) {
      console.log(err);
      return err;
    }
  },
  // eslint-disable-next-line consistent-return
  async getProgramByNameOrIdOrSharableUrl({ commit, dispatch }, data) {
    try {
      const resp = await axios.get(
        `${process.env.VUE_APP_PROGRAM_DOC}/program?type=${data.type}&program_id=${data.program_id}&program_name=${data.program_name}&sharable_url=${data.sharable_url}`,
        {
          headers: {
            'Content-Type': 'application/json'
          }
        }
      );
      commit(MutationTypes.GET_PROGRAM, resp.data.data);
      return resp.data.data;
    } catch (err) {
      console.log(err);
    }
  },
  async createStudent({ commit, dispatch }, data) {
    try {
      const resp = await axios.post(`${process.env.VUE_APP_STUDENT_SERVICE}/student`, data, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('apollo-token')}`
        }
      });
      commit(MutationTypes.GET_PROGRAM, resp.data.data);
      return resp.data.data;
    } catch (err) {
      console.log(err);
      return err;
    }
  },
  async createAnonymousStudent({ commit, dispatch }, data) {
    try {
      const resp = await axios.post(
        `${process.env.VUE_APP_STUDENT_SERVICE}/student/anonymous`,
        data,
        {
          headers: {
            'Content-Type': 'application/json'
          }
        }
      );
      // commit(MutationTypes.GET_PROGRAM, resp.data.data);
      return resp.data.data;
    } catch (err) {
      console.log(err);
      return err;
    }
  },

  async getDataWithShareableUrl({ commit, dispatch }, data) {
    const API_ENDPOINT = process.env.VUE_APP_TEAM_SERVICE;
    try {
      const resp = await axios.get(
        `${API_ENDPOINT}/sharable-url?sharable_url=${data.sharable_url}&document_type=${data.document_type}`,
        {
          headers: {
            'Content-Type': 'application/json'
          }
        }
      );
      commit(MutationTypes.GET_PROGRAM, resp.data.data);
      return resp.data.data;
    } catch (err) {
      console.log(err);
      return err;
    }
  },

  async getAllPrograms({ commit, dispatch }, data) {
    const API_ENDPOINT = process.env.VUE_APP_PROGRAM_LIST;
    try {
      const resp = await axios.get(`${API_ENDPOINT}?skip=${0}&limit=${0}`, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('apollo-token')}`
        }
      });
      return resp.data.data;
    } catch (err) {
      console.log(err);
      return err;
    }
  },

  async createScope({ commit, dispatch }, userId) {
    const API_ENDPOINT = process.env.VUE_APP_PROGRAM_DOC;
    try {
      const data = {
        user_id: userId
      };
      const resp = await axios.post(`${API_ENDPOINT}/scope`, data, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('apollo-token')}`
        }
      });
      return resp.data.data;
    } catch (err) {
      console.log(err);
      return err;
    }
  },

  async updateScope({ commit, dispatch }, data) {
    const API_ENDPOINT = process.env.VUE_APP_PROGRAM_DOC;
    try {
      const resp = await axios.patch(`${API_ENDPOINT}/scope`, data, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('apollo-token')}`
        }
      });
      return resp.data.data;
    } catch (err) {
      console.log(err);
      return err;
    }
  },

  async fetchScopeState({ commit, dispatch }, data) {
    const API_ENDPOINT = process.env.VUE_APP_PROGRAM_DOC;
    try {
      const resp = await axios.get(`${API_ENDPOINT}/scope?user_id=${data}`, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('apollo-token')}`
        }
      });
      return resp.data.data;
    } catch (err) {
      console.log(err);
      return err;
    }
  },

  // eslint-disable-next-line consistent-return
  async getVisitedStudents({ commit, dispatch }, data) {
    try {
      const resp = await axios.get(`${process.env.VUE_APP_STUDENT_SERVICE}/visited`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('apollo-token')}`
        }
      });
      return resp.data.data;
    } catch (err) {
      console.log(err);
    }
  },

  async getOrganizationByLink({ commit, dispatch }, link) {
    try {
      const resp = await axios.get(
        `${process.env.VUE_APP_SPONSOR_SERVICE}/get-org-link?invite_link=${link}`,
        {
          headers: {
            'Content-Type': 'application/json'
          }
        }
      );
      return resp.data.data;
    } catch (err) {
      console.log(err);
      return err;
    }
  },

  async getPathways({ commit, dispatch }) {
    try {
      const resp = await axios.get(`${process.env.VUE_APP_PROGRAM_DOC}/get-pathways`, {
        headers: {
          'Content-Type': 'application/json'
        }
      });
      return resp.data.data;
    } catch (err) {
      console.log(err);
      return err;
    }
  },

  async saveMetaSettings({ commit, dispatch }, payload) {
    try {
      const resp = await axios.post(`${process.env.VUE_APP_PROGRAM_DOC}/meta/settings`, payload, {
        headers: {
          'Content-Type': 'application/json'
        }
      });
      commit(MutationTypes.GET_PROGRAM, resp.data.data);
      return resp.data.data;
    } catch (err) {
      console.log(err.response);
      return err.response;
    }
  },

  async fetchTemplates({ commit, dispatch }) {
    const API_ENDPOINT = process.env.VUE_APP_PROGRAM_DOC;
    try {
      const resp = await axios.get(`${API_ENDPOINT}/get-templates`, {
        headers: {
          'Content-Type': 'application/json'
        }
      });
      return resp.data.data;
    } catch (err) {
      console.log(err);
      return err;
    }
  },
  async updateCompleted({ commit, dispatch }, data) {
    const API_ENDPOINT = process.env.VUE_APP_PROGRAM_DOC;
    try {
      const resp = await axios.post(
        `${process.env.VUE_APP_PROGRAM_DOC}/update-completed`,
        {
          adkIndex: data.adkIndex,
          collection_name: data.collection_name,
          name: data.name,
          program_id: data.program_id,
          isCompleted: true
        },
        {
          headers: {
            'Content-Type': 'application/json'
          }
        }
      );
      console.log(resp, 'respt');
      return resp.data;
    } catch (err) {
      console.log(err);
      return err;
    }
  },
  async fetchTemplateById({ commit, dispatch }, templateId) {
    const API_ENDPOINT = process.env.VUE_APP_PROGRAM_SCHEMA_SERVICE;
    try {
      const resp = await axios.get(
        `${API_ENDPOINT}/program-schema?program_schema_id=${templateId}`,
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${localStorage.getItem('apollo-token')}`
          }
        }
      );
      commit(MutationTypes.GET_PROGRAM, resp.data);
      return resp.data;
    } catch (err) {
      console.log(err);
      return err;
    }
  },

  async cloneTemplate({ commit, dispatch }, data) {
    const API_ENDPOINT = process.env.VUE_APP_PROGRAM_SCHEMA_SERVICE;
    try {
      const resp = await axios.post(`${API_ENDPOINT}/clone/template`, data, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('apollo-token')}`
        }
      });
      return resp.data.data;
    } catch (err) {
      console.log(err);
      return err;
    }
  },

  async createTemplate({ commit, dispatch }, data) {
    const API_ENDPOINT = process.env.VUE_APP_PROGRAM_SCHEMA_SERVICE;
    try {
      const resp = await axios.post(`${API_ENDPOINT}/template`, null, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('apollo-token')}`
        }
      });
      return resp.data.data;
    } catch (err) {
      console.log(err);
      return err;
    }
  },

  async cloneProgram({ commit, dispatch }, data) {
    // const API_ENDPOINT = 'http://localhost:3000/stage';
    const API_ENDPOINT = process.env.VUE_APP_PROGRAM_SCHEMA_SERVICE;
    try {
      const resp = await axios.post(`${API_ENDPOINT}/clone/program`, data, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('apollo-token')}`
        }
      });
      return resp.data.data;
    } catch (err) {
      console.log(err.response.data);
      return err.response.data;
    }
  },

  async cloneProgramData({ commit, dispatch }, data) {
    const API_ENDPOINT = process.env.VUE_APP_CLONE_PROGRAM;
    try {
      const resp = await axios.post(`${API_ENDPOINT}/clone-program`, data, {
        headers: {
          'Content-Type': 'application/json'
        }
      });
      commit(MutationTypes.GET_PROGRAM, resp.data.data);
      return resp.data;
    } catch (err) {
      console.log(err.response.data);
      return err.response.data;
    }
  },

  async updateProgramTemplate({ commit, dispatch }, data) {
    const API_ENDPOINT = process.env.VUE_APP_PROGRAM_SCHEMA_SERVICE;
    try {
      const resp = await axios.patch(
        `${API_ENDPOINT}/program-schema?program_schema_id=${data.program_schema_id}`,
        data.payload,
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${localStorage.getItem('apollo-token')}`
          }
        }
      );
      return resp.data;
    } catch (err) {
      console.log(err.response.data);
      return err.response.data;
    }
  },

  async fetchActivities({ commit, dispatch }) {
    const API_ENDPOINT = process.env.VUE_APP_PROGRAM_SCHEMA_SERVICE;
    try {
      const resp = await axios.get(`${API_ENDPOINT}/activities`, {
        headers: {
          'Content-Type': 'application/json'
        }
      });
      return resp.data.data;
    } catch (err) {
      console.log(err);
      return err;
    }
  },

  async getSharableUrlByName({ commit, dispatch }, data) {
    let nameValue = data?.name?.toString().toLowerCase();

    // replace & with and
    nameValue = nameValue.split(/&+/).join('-and-');

    // remove invalid characters
    nameValue = nameValue.split(/[^a-z0-9]/).join('-');

    // remove duplicates
    nameValue = nameValue.split(/-+/).join('-');

    // trim leading & trailing characters
    nameValue = nameValue.trim('-');

    let origin;
    if (process.env.VUE_APP_NODE_ENV === 'stage') {
      origin = 'stage.pilotcity.com';
    } else if (process.env.VUE_APP_NODE_ENV === 'production') {
      origin = 'pilotcity.com';
    } else {
      origin = 'develope.pilotcity.com';
    }
    const sharable_url = `${origin}/${data.type}/${nameValue}`;
    return sharable_url;
  },

  async saveCheckoutQualification({ commit, dispatch }, data) {
    try {
      const resp = await axios.post(
        `${process.env.VUE_APP_PROGRAM_DOC}/checkout/qualification`,
        data,
        {
          headers: {
            'Content-Type': 'application/json'
          }
        }
      );
      commit(MutationTypes.GET_PROGRAM, resp.data.data);
      return resp.data;
    } catch (err) {
      console.log(err);
      return err;
    }
  },

  async saveActivitySettings({ commit, dispatch }, data) {
    try {
      const resp = await axios.patch(
        `${process.env.VUE_APP_PROGRAM_DOC}/activity/settings?document_type=${data?.document_type}&document_id=${data?.document_id}&adk_name=${data?.adk_name}`,
        data.payload,
        {
          headers: {
            'Content-Type': 'application/json'
          }
        }
      );
      commit(MutationTypes.GET_PROGRAM, resp.data.data);
      return resp.data;
    } catch (err) {
      console.log(err);
      return err;
    }
  },

  // eslint-disable-next-line consistent-return
  async getUserPermissions({ commit, dispatch }) {
    try {
      const response = await axios.get(`${process.env.VUE_APP_AUTH0}/permissions`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('apollo-token')}`
        }
      });
      return response.data.data;
    } catch (error) {
      console.log(error);
    }
  },

  // eslint-disable-next-line consistent-return
  async getPermissionsByRole({ commit, dispatch }, data) {
    try {
      const response = await axios.get(
        `${process.env.VUE_APP_USER_SERVICE}/permissions?table=${data?.table}&roles=${data?.roles}`,
        {
          headers: {
            'Content-Type': 'application/json'
          }
        }
      );
      return response.data.data;
    } catch (error) {
      console.log(error);
    }
  },

  async fetchAllOrganizations({ commit, dispatch }) {
    try {
      const res = await axios.get(`${process.env.VUE_APP_USER_SERVICE}/organization`, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('apollo-token')}`
        }
      });
      return res.data.data;
    } catch (error) {
      return error;
    }
  },

  async checkoutFreeProgram({ commit, dispatch }, payload) {
    const API_ENDPOINT = process.env.VUE_APP_REDIS_SERVICE;
    try {
      const resp = await axios.post(`${API_ENDPOINT}/checkout-free`, payload, {
        headers: {
          'Content-Type': 'application/json'
        }
      });
      commit(MutationTypes.GET_PROGRAM, resp.data.data);
      return resp.data.data;
    } catch (err) {
      console.log(err);
      return err;
    }
  },

  getDomainByEnvironmentName({ commit, dispatch }) {
    let domain;
    if (process.env.VUE_APP_NODE_ENV === 'development') {
      domain = 'develop.pilotcity.com';
    } else if (process.env.VUE_APP_NODE_ENV === 'stage') {
      domain = 'stage.pilotcity.com';
    } else {
      domain = 'pilotcity.com';
    }
    return domain;
  },

  async saveDraft({ commit, dispatch }, payload) {
    try {
      const resp = await axios.post(`${process.env.VUE_APP_REVISION_SERVICE}/draft`, payload, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('apollo-token')}`
        }
      });
      return resp.data.data;
    } catch (err) {
      console.log(err.response);
      return err.response;
    }
  },

  // eslint-disable-next-line consistent-return
  async deleteDraft({ commit, dispatch }, payload) {
    try {
      const resp = await axios.delete(
        `${process.env.VUE_APP_REVISION_SERVICE}/draft?program_id=${payload?.program_id}`,
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${localStorage.getItem('apollo-token')}`
          }
        }
      );
      return resp.data.data;
    } catch (err) {
      console.log(err.response);
    }
  },

  // eslint-disable-next-line consistent-return
  async fetchDraft({ commit, dispatch }, payload) {
    try {
      const resp = await axios.get(
        `${process.env.VUE_APP_REVISION_SERVICE}/draft?program_id=${payload?.program_id}`,
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${localStorage.getItem('apollo-token')}`
          }
        }
      );
      commit(MutationTypes.GET_PROGRAM, resp.data.data);
      return resp.data.data;
    } catch (err) {
      console.log(err.response);
    }
  },

  async reAttachVideoaskResponse({ commit, dispatch }, data) {
    try {
      const resp = await axios.post(
        `${process.env.VUE_APP_VIDEOASK_SERVICE}/reattach/videoask`,
        data,
        {
          headers: {
            'Content-Type': 'application/json'
          }
        }
      );
      return resp.data;
    } catch (err) {
      console.log(err);
      return err.response.data;
    }
  },
  // eslint-disable-next-line consistent-return
  async saveActivityStartedOrEndedTime({ commit, dispatch }, data) {
    try {
      const resp = await axios.post(`${process.env.VUE_APP_STUDENT_SERVICE}/activity/time`, data, {
        headers: {
          'Content-Type': 'application/json'
        }
      });
      return resp.data.data;
    } catch (err) {
      console.log(err);
    }
  },
  // eslint-disable-next-line consistent-return
  async saveRewardsAndCredentials({ commit, dispatch }, data) {
    try {
      const resp = await axios.post(
        `${process.env.VUE_APP_PROGRAM_DOC}/rewards-credentials`,
        data,
        {
          headers: {
            'Content-Type': 'application/json'
          }
        }
      );
      commit(MutationTypes.GET_PROGRAM, resp.data.data);
      return resp.data;
    } catch (err) {
      console.log(err);
      // return err;
    }
  },
  // eslint-disable-next-line consistent-return
  async addBadges({ commit, dispatch }, data) {
    try {
      const resp = await axios.post(`${process.env.VUE_APP_STUDENT_SERVICE}/badges`, data, {
        headers: {
          'Content-Type': 'application/json'
        }
      });
      commit(MutationTypes.GET_PROGRAM, resp.data);
      return resp.data;
    } catch (err) {
      console.log(err);
      // return err;
    }
  },
  // eslint-disable-next-line consistent-return
  async fetchOrgRoles({ commit, dispatch }, data) {
    try {
      const resp = await axios.get(`${process.env.VUE_APP_SPONSOR_SERVICE}/org-roles`, {
        headers: {
          'Content-Type': 'application/json'
        }
      });
      return resp.data.data;
    } catch (err) {
      console.log(err);
    }
  },
  // eslint-disable-next-line consistent-return
  async addMatterPortData({ commit, dispatch }, data) {
    const API_ENDPOINT = process.env.VUE_APP_PROGRAM_DOC;
    try {
      const resp = await axios.post(`${API_ENDPOINT}/matterport`, data, {
        headers: {
          'Content-Type': 'application/json'
        }
      });
      commit(MutationTypes.GET_PROGRAM, resp.data.data);
      return resp.data.data;
    } catch (err) {
      console.log(err);
    }
  },
  // eslint-disable-next-line consistent-return
  async saveActivityFeedback({ commit, dispatch }, data) {
    try {
      const resp = await axios.post(
        `${process.env.VUE_APP_STUDENT_SERVICE}/activity-feedback`,
        data,
        {
          headers: {
            'Content-Type': 'application/json'
          }
        }
      );
      commit(MutationTypes.GET_PROGRAM, resp.data.data);
      return resp.data;
    } catch (err) {
      console.log(err);
      // return err;
    }
  },
  // eslint-disable-next-line consistent-return
  async createAutomationProgram({ commit, dispatch }, data) {
    const API_ENDPOINT = process.env.VUE_APP_AUTOMATE_SETUP_SERVICE;
    try {
      const url = `${API_ENDPOINT}/program-templateid`;
      // const url = `http://localhost:3000/stage/program-templateid`;
      const resp = await axios.post(url, data, {
        headers: {
          'Content-Type': 'application/json'
        }
      });
      return resp.data.data;
    } catch (err) {
      console.log(err);
      // return err;
    }
  },
  // eslint-disable-next-line consistent-return
  async simulateScope({ commit, dispatch }, data) {
    const API_ENDPOINT = process.env.VUE_APP_AUTOMATE_SETUP_SERVICE;
    try {
      const resp = await axios.post(`${API_ENDPOINT}/simulate/scope`, data, {
        headers: {
          'Content-Type': 'application/json'
        }
      });
      return resp.data.data;
    } catch (err) {
      console.log(err);
      // return err;
    }
  },
  // eslint-disable-next-line consistent-return
  async simulateProgramDetails({ commit, dispatch }, data) {
    const API_ENDPOINT = process.env.VUE_APP_AUTOMATE_SETUP_SERVICE;
    try {
      const resp = await axios.post(`${API_ENDPOINT}/simulate/program/details`, data, {
        headers: {
          'Content-Type': 'application/json'
        }
      });
      return resp.data.data;
    } catch (err) {
      console.log(err);
      // return err;
    }
  },
  // eslint-disable-next-line consistent-return
  async updateAutomationAdk({ commit, dispatch }, data) {
    const API_ENDPOINT = process.env.VUE_APP_AUTOMATE_SETUP_SERVICE;
    try {
      const resp = await axios.patch(`${API_ENDPOINT}/program/adk`, data, {
        headers: {
          'Content-Type': 'application/json'
        }
      });
      return resp.data.data;
    } catch (err) {
      console.log(err);
      // return err;
    }
  },

  async getProblemScope({ commit, dispatch }, payload) {
    try {
      const resp = await axios.post(`${process.env.VUE_APP_STUDENT_SERVICE}/problem`, payload, {
        headers: {
          'Content-Type': 'application/json'
        }
      });
      return resp.data.data;
    } catch (err) {
      console.log(err);
      return err;
    }
  },

  async saveProgressOfSolveADK({ commit, dispatch }, payload) {
    try {
      const resp = await axios.post(`${process.env.VUE_APP_STUDENT_SERVICE}/progress`, payload, {
        headers: {
          'Content-Type': 'application/json'
        }
      });
      return resp.data.data;
    } catch (err) {
      return err.response;
    }
  }
};
